import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Aínsa is one of the most popular mtb destination in Spain. The Zona Zero is
well known for its enduro tracks since the EWS took place in 2015.`}</p>
    <br />
    <p>{`More than
20 official enduro routes surround the medieval village. The trails are mostly
restored from old pastoral paths and from alpine mountains to rolling and
lower hills, you have an unlimited variety of terrains.`}</p>
    <br />
    <p>{`In several old abandoned villages you can ride through the Spanish history.
The EWS tour will stop here again in 2018.`}</p>
    <br />
    <p>{`You can take a shortcut and take a shuttle, but most
of the trails you have to earn yourself. Best time to go is March to December.
Depending on the weather, you can ride the whole year in the lower trails.`}</p>
    <TrailguidePlugin content="lat=42.4280&lng=0.1288" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=11,42.4280,0.1288"
        }}>{`web-app`}</a></p>
    </p>
    <br />
    <h4>{`Coda Sartén`}</h4>
    <p>{`Fast and flowy with a surprise at the end. Here you see the -Margas- called terrain.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2484"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/ainsa_3.jpg" mdxType="Image" />
    <br />
    <h4>{`Luparuelo`}</h4>
    <p>{`Around Aínsa you can ride through Spanish history. There are a lot of
abandoned villages like Morcat on the picture.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2495"
      }}>{`here`}</a>{` to open the trail on Trailguide.`}</p>
    <Image src="destinations/ainsa_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Aínsa`}</h4>
    <p>{`The village of Aínsa has a medieval center on the hills between
the Ara and Chinca river.`}</p>
    <Image src="destinations/ainsa_4.jpg" mdxType="Image" />
    <br />
    <h4>{`Sarrastaño`}</h4>
    <p>{`Also the villages around Aínsa are worth a visit.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2496"
      }}>{`here`}</a>{` to open the trail on Trailguide.`}</p>
    <Image src="destinations/ainsa_6.jpg" mdxType="Image" />
    <br />
    <p>{`South of Aínsa you ride in hilly terrain. North of it, the higher
mountains of the Pyrenees begin.`}</p>
    <Image src="destinations/ainsa_7.jpg" mdxType="Image" />
    <br />
    <h4>{`Maxiavalance V2`}</h4>
    <p>{`With great and long trails like the Maxiavalanche V2.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2485"
      }}>{`here`}</a>{` to open the trail on Trailguide.`}</p>
    <Image src="destinations/ainsa_5.jpg" mdxType="Image" />
    <br />
    <h4>{`Coño del Mundo`}</h4>
    <p>{`Technical section on the Coño del Mundo.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2482"
      }}>{`here`}</a>{` to open the trail on Trailguide.`}</p>
    <Image src="destinations/ainsa_2.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      